var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", sm: "10" } },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-space-between" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mb-3",
                      attrs: { color: "primary" },
                      on: { click: _vm.closeDetail },
                    },
                    [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                    1
                  ),
                ],
                1
              ),
              _vm.api.isLoading
                ? _c("v-skeleton-loader", {
                    ref: "skeleton",
                    attrs: { type: "table" },
                  })
                : _vm._e(),
              _vm.manual != null
                ? _c(
                    "v-card",
                    { staticClass: "pa-3", attrs: { elevation: "0" } },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { color: "secondary white--text", flat: "" } },
                        [
                          _c("v-toolbar-title", { staticClass: "text-h4" }, [
                            _vm._v(" How to " + _vm._s(_vm.manual.name) + " "),
                          ]),
                          _c("v-spacer"),
                          this.$store.getters.getRole == "operation"
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.redirectUpdateSteps },
                                },
                                [_vm._v(" Update steps ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._l(_vm.manualStep, function (item, index) {
                        return _c(
                          "v-card",
                          { key: index, staticClass: " mt-3 mb-6" },
                          [
                            _c(
                              "v-sheet",
                              [
                                item.title != "null"
                                  ? _c(
                                      "v-toolbar",
                                      {
                                        staticClass: "text-h6",
                                        attrs: { flat: "" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(index + 1 + " ) ") +
                                            " " +
                                            _vm._s(item.title) +
                                            " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("hr", { staticClass: "ma-4" }),
                                item.attachment_url != null
                                  ? _c("v-img", {
                                      staticClass: "mx-auto",
                                      style: { cursor: _vm.cursorStyle },
                                      attrs: {
                                        src: item.attachment_url,
                                        width: _vm.imageSize,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.redirectReadImage(
                                            item.attachment_url
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            item.step != "null" || item.step != " "
                              ? _c(
                                  "v-container",
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-h5 ma-3 font-weight-bold",
                                      },
                                      [_vm._v(" Description ")]
                                    ),
                                    _c("mavon-editor", {
                                      staticStyle: {
                                        "z-index": "0",
                                        "min-height": "100px",
                                        height: "auto",
                                        width: "100%",
                                      },
                                      attrs: {
                                        "elevation-1": "",
                                        height: "auto",
                                        width: "auto",
                                        defaultOpen: "preview",
                                        toolbarsFlag: false,
                                        subfield: false,
                                        language: "en",
                                        editable: false,
                                      },
                                      model: {
                                        value: item.step,
                                        callback: function ($$v) {
                                          _vm.$set(item, "step", $$v)
                                        },
                                        expression: "item.step",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      _vm.manualStep.length == 0
                        ? _c("v-card", [
                            _c("div", { staticClass: "text-h5 pa-3" }, [
                              _vm._v(" No manual found "),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }